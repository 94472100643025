<template>
  <datepicker
    v-if="fechaModel || canNull"
    v-model="fechaModel"
    :locale="es"
    :inputFormat="'dd/MM/yyyy'"
    :class="[{'w-full': widthFull, 'w-24': !widthFull}, 'rounded border px-1']"
    required
    :disabled="disabled"
  />
</template>

<script>
import { computed, defineComponent, toRef } from "vue";
import { format, parse, parseISO } from "date-fns";

import Datepicker from "vue3-datepicker";
import { es } from "date-fns/esm/locale";
export default defineComponent({
  components: {
    Datepicker,
  },
  props: {
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    canNull: {
      type: Boolean,
      required: false,
      default: false
    },
    widthFull: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ["update:value"],
  setup(props, { emit }) {
    let fecha = toRef(props, "value");
    let canNull = toRef(props, "canNull");
    
    // Inicializa en caso de que venga vacío
    if (!fecha.value && !canNull.value){
      emit("update:value", new Date());
    }
  
    const fechaModel = computed({
      get() {
        return fecha.value;
      },
      set(newFecha) {
        emit("update:value", newFecha);
      },
    });

    return { es, fecha, fechaModel };
  },
});
</script>
